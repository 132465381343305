<template>
  <div class="page-content">
    <div class="page-bg">
      <p>{{$t("questionaire.questionnaireSuccess")}}</p>
      <img src="../../assets/images/wj-icon.png" alt="">
    </div>
    <div class="page-btn">
      <!-- <p class="submit-btn">
        <router-link to="/">
          <van-button round type="info" block color="linear-gradient(#499FFF,#49A5FF,#49C1FF)">
            {{$t("questionaire.gohome")}}
          </van-button>
        </router-link>
      </p> -->
      <p class="submit-btn">
        <van-button round block plain @click="close" type="info">{{$t("questionaire.close")}}</van-button>
      </p>
    </div>
  </div>

</template>
<script>
export default {
  methods:{
    close(){
      if(this.$store.getters.isApp){
        let isAndroid = navigator.userAgent.indexOf('Android') > -1 || navigator.userAgent.indexOf('Adr') > -1;
        let isiOS = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
        if (isAndroid) {
            window.native.qnPageClose();
            return false;
        } else {
            window.webkit.messageHandlers.qnPageClose.postMessage({})
            return false;
        }
      }
      var ua = navigator.userAgent.toLowerCase();
      if(ua.match(/MicroMessenger/i)=="micromessenger") {
          WeixinJSBridge.call('closeWindow');
      } else {
          if (navigator.userAgent.indexOf("MSIE") > 0) {     
            if (navigator.userAgent.indexOf("MSIE 6.0") > 0) {     
                window.opener = null; 
                window.close();     
            }else {     
                window.open('', '_top'); 
                window.top.close();     
            }
        }     
        else if (navigator.userAgent.indexOf("Firefox") > 0) {     
            window.location.href = 'about:blank '; //火狐默认状态非window.open的页面window.close是无效的    
            //window.history.go(-2);     
        }     
        else {     
            window.opener = null;      
            window.open('', '_self', '');     
            window.close();     
        }
      }
    
    }
  }
}
</script>
<style lang="scss" scoped>
.page-content{
  width:100%;
  height:100%;

  .page-bg{
    width:100%;
    height:12.5rem;
    background: url("../../assets/images/header.png") no-repeat;
    background-size:cover ;
    position: relative;
    p{
      text-align: center;
      font-size:1rem;
      color:#fff;
      padding-top:4.5rem;
    }
    img{
      width:6.5rem;
      height:6.5rem;
      position: absolute;
      left:0;
      right:0;
      bottom:-2rem;
      margin:0 auto;
    }
  }
  .page-btn{
    padding-top:20%;
    .submit-btn{
      width:60%;
      margin-left:20%;
      padding-top:1rem;
      .van-button {
        height: 1.6rem;
      }
    }
  }
}


</style>
